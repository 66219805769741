<!-- eslint-disable no-undef -->
<template>
  <div>
    <h2 class="flex">
      <TimeBle
        :seconds="timeD.seconds"
        :minutes="timeD.minutes"
        :hours="timeD.hours"
      />
    </h2>
    <div class="flexc">
      <div
        class="center padding"
        v-if="showTimeRanges === false && showTimeStints === false"
      >
        <h2>Current time range: {{ timeRange / 60 }} minutes</h2>
        <Button
          type="button"
          @click="
            showTimeRanges = true;
            showTimeStints = false;
          "
          >Change</Button
        >
      </div>
      <div class="flex" v-if="showTimeRanges === true">
        <p>
          <Button
            type="button"
            @click="
              setTimeRanege(900);
              reset();
            "
            :class="{ selected: selected === 900 }"
            >15 minutes</Button
          >
          <Button
            type="button"
            @click="
              setTimeRanege(1200);
              reset();
            "
            :class="{ selected: selected === 1200 }"
            >20 minutes</Button
          >
          <Button
            type="button"
            @click="
              setTimeRanege(1500);
              reset();
            "
            :class="{ selected: selected === 1500 }"
            >25 minutes</Button
          >
        </p>
        <p>
          <Button
            type="button"
            @click="
              setTimeRanege(1800);
              reset();
            "
            :class="{ selected: selected === 1800 }"
            >30 minutes</Button
          >
          <Button
            type="button"
            @click="
              setTimeRanege(2100);
              reset();
            "
            :class="{ selected: selected === 2100 }"
            >35 minutes</Button
          >
          <Button
            type="button"
            @click="
              setTimeRanege(2400);
              reset();
            "
            :class="{ selected: selected === 2400 }"
            >40 minutes</Button
          >
        </p>
        <p>
          <Button
            type="button"
            @click="
              setTimeRanege(2700);
              reset();
            "
            :class="{ selected: selected === 2700 }"
            >45 minutes</Button
          >
          <Button
            type="button"
            @click="
              setTimeRanege(3000);
              reset();
            "
            :class="{ selected: selected === 3000 }"
            >50 minutes</Button
          >
          <Button
            type="button"
            @click="
              setTimeRanege(3300);
              reset();
            "
            :class="{ selected: selected === 3300 }"
            >55 minutes</Button
          >
        </p>
      </div>
      <div class="center full" v-if="showTimeRanges === true">
        <Button
          type="button"
          @click="
            showTimeRanges = false;
            saveTimeRange();
          "
          >Save</Button
        >
        <!--Button type="button" @click="showTimeRanges = false">Cancel</!--Button//-->
      </div>
      <div
        class="center padding"
        v-if="showTimeStints === false && showTimeRanges === false"
      >
        <h2>Timers: {{ slowTime }}s slow | {{ fastTime }}s fast</h2>
        <Button
          type="button"
          @click="
            showTimeStints = true;
            showTimeRanges = false;
          "
          >Change Stints</Button
        >
      </div>
      <div class="center" v-if="showTimeStints === true">
        <h2>
          <div class="flex">
            <Button type="button" @click="slowTimeRef -= 100">-100</Button
            ><Button type="button" @click="slowTimeRef -= 10">-10</Button
            ><Button type="button" @click="slowTimeRef--">-1</Button>
            <h1>{{ slowTimeRef }}</h1>
            <Button type="button" @click="slowTimeRef++">+1</Button
            ><Button type="button" @click="slowTimeRef += 10">+10</Button
            ><Button type="button" @click="slowTimeRef += 100">+100</Button>
          </div>
          <div class="flex">
            <Button type="button" @click="fastTimeRef -= 100">-100</Button
            ><Button type="button" @click="fastTimeRef -= 10">-10</Button
            ><Button type="button" @click="fastTimeRef--">-1</Button>
            <h1>{{ fastTimeRef }}</h1>
            <Button type="button" @click="fastTimeRef++">+1</Button
            ><Button type="button" @click="fastTimeRef += 10">+10</Button
            ><Button type="button" @click="fastTimeRef += 100">+100</Button>
          </div>
        </h2>
        <Button
          type="button"
          @click="
            saveTimeStints();
            showTimeStints = false;
          "
          >Save</Button
        >
        <Button type="button" @click="showTimeStints = false">Cancel</Button>
      </div>
    </div>
    <div>&nbsp;</div>
    <div class="flex">
      <TimerBle :seconds="timer.seconds" :minutes="timer.minutes" />
      <!--PercentBle
        :percentLeft="percentDec.split('')[0]"
        :percentRight="percentDec.split('')[1]"
        :fractionLeft="percentFrac.split('')[0]"
        :fractionRight="percentFrac.split('')[1]"
      /-->
      <div v-if="showTimers === true" class="flexb">
        <h1>{{ percentDec }},</h1>
        <h2>{{ percentFrac }} % left</h2>
      </div>
    </div>
    <div class="flex">
      <Button
        type="button"
        @click="
          startTimers();
          showTimers = true;
        "
        >Start</Button
      >
      <Button type="button" @click="pauseTimers()">Pause</Button>
      <Button type="button" @click="resumeTimers()">Resume</Button>
      <Button type="button" @click="reload()"> Restart </Button>
      <Button type="button" @click="reset()"> Reset </Button>
    </div>
  </div>
  <div
    v-if="showTimers === true"
    :class="{ intervalFast: swap, intervalSlow: !swap }"
    class="full"
  >
    <p></p>
    <h2 v-if="swap === true" class="flex">
      Fast <span v-if="fastTimes > 0">{{ fastTimes }} left</span>
      <span v-if="fastTimes < 1">last</span>
    </h2>
    <h2 v-if="swap === false" class="flex">
      Slow <span v-if="slowTimes > 0">{{ slowTimes }} left</span>
      <span v-if="slowTimes < 1">last</span>
    </h2>
    <h1 class="flex">
      <TimerBle :seconds="timerSlow.seconds" :minutes="timerSlow.minutes" />
    </h1>
  </div>
</template>

<script setup lang="ts">
//import {  watchEffect, onMounted } from "vue";
import { useTimer } from "vue-timer-hook";
import { useTime } from "vue-timer-hook";
import TimerBle from "./TimerBle.vue";
import TimeBle from "./TimeBle.vue";
import PercentBle from "./PercentBle.vue";
import Button from "./ButtonBle.vue";
import { ref } from "vue";
import { watchEffect } from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  expiryTimestamp: {
    type: Number,
    required: true,
  },
});

var timeRange = 900;
if (localStorage.getItem("timeRaange")) {
  timeRange = Number(localStorage.getItem("timeRaange"));
}
var selected = ref(timeRange);
var slowTime = 90;
var fastTime = 30;
if (localStorage.getItem("slow")) {
  slowTime = Number(localStorage.getItem("slow"));
}
if (localStorage.getItem("fast")) {
  fastTime = Number(localStorage.getItem("fast"));
}
var slowTimeRef = ref(slowTime);
var fastTimeRef = ref(fastTime);
var showTimers = ref(false);
var showTimeRanges = ref(false);
var showTimeStints = ref(false);
var swap = ref(false);
var percent = ref(100);
var percentDec = ref("");
var percentFrac = ref("");
var generalTimer = new Date();
var goPercent = false;
var progress = 0;
var slowTimes = -1;
var fastTimes = 0;
const timer = useTimer(getTimestampRange(timeRange), false);
const timerSlow = useTimer(getTimestampRange(slowTime), false);
const timeD = useTime();

function getTimestampRange(range: number, correction = 0): number {
  generalTimer = new Date();
  const rangeMil = range * 1000;
  const correctionMil = correction * 1000;
  return generalTimer.getTime() + rangeMil + correctionMil;
}

const reload = () => {
  // Restarts to 10 minutes timer
  goPercent = false;
  const start = getTimestampRange(0);
  const end = getTimestampRange(timeRange);
  setTimeout(() => {
    timer.restart(getTimestampRange(timeRange));
    timerSlow.restart(getTimestampRange(slowTime));
    goPercent = true;
    computePercent(start, end);
    calculateTimes();
  }, 200);
};
const reset = () => {
  // Restarts to 10 minutes timer
  timer.restart(getTimestampRange(timeRange));
  timer.pause();
  timerSlow.restart(getTimestampRange(slowTime));
  timerSlow.pause();
  showTimers.value = false;
  goPercent = false;
  calculateTimes();
};
const startTimers = () => {
  const start = getTimestampRange(0);
  const end = getTimestampRange(timeRange);
  //time.setSeconds(time.getSeconds() + timeRange);
  timer.restart(getTimestampRange(timeRange));
  //timer.start();
  timerSlow.restart(getTimestampRange(slowTime));
  //timerSlow.start();
  goPercent = true;
  computePercent(start, end);
  calculateTimes();
};
const pauseTimers = () => {
  timer.pause();
  timerSlow.pause();
  goPercent = false;
};
const resumeTimers = () => {
  timer.resume();
  timerSlow.resume();
  const start = getTimestampRange(0);
  console.log(progress);
  const end = getTimestampRange(timeRange - progress / 1000);
  goPercent = true;
  computePercent(start, end);
};
const computePercent = (start: number, end: number) => {
  const now = new Date();
  const nowTimestamp = now.getTime();
  const total = end - start;
  progress = nowTimestamp - start;
  const p = 99.99 - (progress / total) * 100;
  const percentText = p.toFixed(2).split(".");
  percentDec.value = percentText[0];
  if (percentDec.value.length < 2) percentDec.value = "0" + percentDec.value;
  percentFrac.value = percentText[1];
  if (percentFrac.value.length < 2) percentFrac.value = "0" + percentFrac.value;
  if (goPercent === true) {
    setTimeout(() => {
      computePercent(start, end);
    }, 100);
  }
};
function calculateTimes() {
  let sum = 0;
  let slowFast = false;
  slowTimes = -1;
  fastTimes = 0;
  while (sum < timeRange) {
    if (slowFast === false) {
      sum += slowTime;
      slowTimes++;
      slowFast = !slowFast;
    } else {
      sum += fastTime;
      fastTimes++;
      slowFast = !slowFast;
    }
  }
}
function saveTimeRange() {
  localStorage.setItem("timeRaange", timeRange.toString());
}
function saveTimeStints() {
  slowTime = slowTimeRef.value;
  fastTime = fastTimeRef.value;
  localStorage.setItem("slow", slowTime.toString());
  localStorage.setItem("fast", fastTime.toString());
}
function setTimeRanege(range: number) {
  timeRange = range;
  selected.value = timeRange;
}
watchEffect(async () => {
  if (timer.isExpired.value) {
    console.warn("IsExpired");
    timerSlow.restart(getTimestampRange(timeRange));
    timerSlow.pause();
    showTimers.value = false;
  }
  if (timerSlow.isExpired.value) {
    console.warn("Slow IsExpired");
    if (swap.value === false) {
      fastTimes--;
      timerSlow.restart(getTimestampRange(fastTime));
      swap.value = true;
    } else {
      slowTimes--;
      timerSlow.restart(getTimestampRange(slowTime));
      swap.value = false;
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.intervalSlow {
  background-color: #ddddff;
}
.intervalFast {
  background-color: #ffdddd;
}
.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}
.flexb {
  display: flex;
  width: 200px;
  padding-left: 50px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
.flexc {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
}
.center {
  text-align: center;
}
.padding {
  padding: 0 50px;
}
.full {
  /*display: flex;
  flex-direction: collumn;
  flex-wrap: no-wrap;
  justify-content: center;
  gap: 30px;*/
  width: 100%;
}
.selected {
  background-color: rgb(176, 249, 170);
}
</style>
