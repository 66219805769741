import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flexc" }
const _hoisted_3 = {
  key: 0,
  class: "center padding"
}
const _hoisted_4 = {
  key: 1,
  class: "flex"
}
const _hoisted_5 = {
  key: 2,
  class: "center full"
}
const _hoisted_6 = {
  key: 3,
  class: "center padding"
}
const _hoisted_7 = {
  key: 4,
  class: "center"
}
const _hoisted_8 = { class: "flex" }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = {
  key: 0,
  class: "flexb"
}
const _hoisted_12 = { class: "flex" }
const _hoisted_13 = {
  key: 0,
  class: "flex"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  key: 1,
  class: "flex"
}
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "flex" }

import { useTimer } from "vue-timer-hook";
import { useTime } from "vue-timer-hook";
import TimerBle from "./TimerBle.vue";
import TimeBle from "./TimeBle.vue";
import PercentBle from "./PercentBle.vue";
import Button from "./ButtonBle.vue";
import { ref } from "vue";
import { watchEffect } from "vue";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'HelloWorld',
  props: {
  expiryTimestamp: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

//import {  watchEffect, onMounted } from "vue";
const props = __props;

var timeRange = 900;
if (localStorage.getItem("timeRaange")) {
  timeRange = Number(localStorage.getItem("timeRaange"));
}
var selected = ref(timeRange);
var slowTime = 90;
var fastTime = 30;
if (localStorage.getItem("slow")) {
  slowTime = Number(localStorage.getItem("slow"));
}
if (localStorage.getItem("fast")) {
  fastTime = Number(localStorage.getItem("fast"));
}
var slowTimeRef = ref(slowTime);
var fastTimeRef = ref(fastTime);
var showTimers = ref(false);
var showTimeRanges = ref(false);
var showTimeStints = ref(false);
var swap = ref(false);
var percent = ref(100);
var percentDec = ref("");
var percentFrac = ref("");
var generalTimer = new Date();
var goPercent = false;
var progress = 0;
var slowTimes = -1;
var fastTimes = 0;
const timer = useTimer(getTimestampRange(timeRange), false);
const timerSlow = useTimer(getTimestampRange(slowTime), false);
const timeD = useTime();

function getTimestampRange(range: number, correction = 0): number {
  generalTimer = new Date();
  const rangeMil = range * 1000;
  const correctionMil = correction * 1000;
  return generalTimer.getTime() + rangeMil + correctionMil;
}

const reload = () => {
  // Restarts to 10 minutes timer
  goPercent = false;
  const start = getTimestampRange(0);
  const end = getTimestampRange(timeRange);
  setTimeout(() => {
    timer.restart(getTimestampRange(timeRange));
    timerSlow.restart(getTimestampRange(slowTime));
    goPercent = true;
    computePercent(start, end);
    calculateTimes();
  }, 200);
};
const reset = () => {
  // Restarts to 10 minutes timer
  timer.restart(getTimestampRange(timeRange));
  timer.pause();
  timerSlow.restart(getTimestampRange(slowTime));
  timerSlow.pause();
  showTimers.value = false;
  goPercent = false;
  calculateTimes();
};
const startTimers = () => {
  const start = getTimestampRange(0);
  const end = getTimestampRange(timeRange);
  //time.setSeconds(time.getSeconds() + timeRange);
  timer.restart(getTimestampRange(timeRange));
  //timer.start();
  timerSlow.restart(getTimestampRange(slowTime));
  //timerSlow.start();
  goPercent = true;
  computePercent(start, end);
  calculateTimes();
};
const pauseTimers = () => {
  timer.pause();
  timerSlow.pause();
  goPercent = false;
};
const resumeTimers = () => {
  timer.resume();
  timerSlow.resume();
  const start = getTimestampRange(0);
  console.log(progress);
  const end = getTimestampRange(timeRange - progress / 1000);
  goPercent = true;
  computePercent(start, end);
};
const computePercent = (start: number, end: number) => {
  const now = new Date();
  const nowTimestamp = now.getTime();
  const total = end - start;
  progress = nowTimestamp - start;
  const p = 99.99 - (progress / total) * 100;
  const percentText = p.toFixed(2).split(".");
  percentDec.value = percentText[0];
  if (percentDec.value.length < 2) percentDec.value = "0" + percentDec.value;
  percentFrac.value = percentText[1];
  if (percentFrac.value.length < 2) percentFrac.value = "0" + percentFrac.value;
  if (goPercent === true) {
    setTimeout(() => {
      computePercent(start, end);
    }, 100);
  }
};
function calculateTimes() {
  let sum = 0;
  let slowFast = false;
  slowTimes = -1;
  fastTimes = 0;
  while (sum < timeRange) {
    if (slowFast === false) {
      sum += slowTime;
      slowTimes++;
      slowFast = !slowFast;
    } else {
      sum += fastTime;
      fastTimes++;
      slowFast = !slowFast;
    }
  }
}
function saveTimeRange() {
  localStorage.setItem("timeRaange", timeRange.toString());
}
function saveTimeStints() {
  slowTime = slowTimeRef.value;
  fastTime = fastTimeRef.value;
  localStorage.setItem("slow", slowTime.toString());
  localStorage.setItem("fast", fastTime.toString());
}
function setTimeRanege(range: number) {
  timeRange = range;
  selected.value = timeRange;
}
watchEffect(async () => {
  if (timer.isExpired.value) {
    console.warn("IsExpired");
    timerSlow.restart(getTimestampRange(timeRange));
    timerSlow.pause();
    showTimers.value = false;
  }
  if (timerSlow.isExpired.value) {
    console.warn("Slow IsExpired");
    if (swap.value === false) {
      fastTimes--;
      timerSlow.restart(getTimestampRange(fastTime));
      swap.value = true;
    } else {
      slowTimes--;
      timerSlow.restart(getTimestampRange(slowTime));
      swap.value = false;
    }
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("h2", _hoisted_1, [
        _createVNode(TimeBle, {
          seconds: _unref(timeD).seconds,
          minutes: _unref(timeD).minutes,
          hours: _unref(timeD).hours
        }, null, 8, ["seconds", "minutes", "hours"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_unref(showTimeRanges) === false && _unref(showTimeStints) === false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("h2", null, "Current time range: " + _toDisplayString(_unref(timeRange) / 60) + " minutes", 1),
              _createVNode(Button, {
                type: "button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => {
            _isRef(showTimeRanges) //@ts-ignore
 ? showTimeRanges.value = true : showTimeRanges = true;
            _isRef(showTimeStints) //@ts-ignore
 ? showTimeStints.value = false : showTimeStints = false;
          })
              }, {
                default: _withCtx(() => _cache[31] || (_cache[31] = [
                  _createTextVNode("Change")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_unref(showTimeRanges) === true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("p", null, [
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => {
              setTimeRanege(900);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 900 })
                }, {
                  default: _withCtx(() => _cache[32] || (_cache[32] = [
                    _createTextVNode("15 minutes")
                  ])),
                  _: 1
                }, 8, ["class"]),
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => {
              setTimeRanege(1200);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 1200 })
                }, {
                  default: _withCtx(() => _cache[33] || (_cache[33] = [
                    _createTextVNode("20 minutes")
                  ])),
                  _: 1
                }, 8, ["class"]),
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => {
              setTimeRanege(1500);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 1500 })
                }, {
                  default: _withCtx(() => _cache[34] || (_cache[34] = [
                    _createTextVNode("25 minutes")
                  ])),
                  _: 1
                }, 8, ["class"])
              ]),
              _createElementVNode("p", null, [
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => {
              setTimeRanege(1800);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 1800 })
                }, {
                  default: _withCtx(() => _cache[35] || (_cache[35] = [
                    _createTextVNode("30 minutes")
                  ])),
                  _: 1
                }, 8, ["class"]),
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => {
              setTimeRanege(2100);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 2100 })
                }, {
                  default: _withCtx(() => _cache[36] || (_cache[36] = [
                    _createTextVNode("35 minutes")
                  ])),
                  _: 1
                }, 8, ["class"]),
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => {
              setTimeRanege(2400);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 2400 })
                }, {
                  default: _withCtx(() => _cache[37] || (_cache[37] = [
                    _createTextVNode("40 minutes")
                  ])),
                  _: 1
                }, 8, ["class"])
              ]),
              _createElementVNode("p", null, [
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => {
              setTimeRanege(2700);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 2700 })
                }, {
                  default: _withCtx(() => _cache[38] || (_cache[38] = [
                    _createTextVNode("45 minutes")
                  ])),
                  _: 1
                }, 8, ["class"]),
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[8] || (_cache[8] = ($event: any) => {
              setTimeRanege(3000);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 3000 })
                }, {
                  default: _withCtx(() => _cache[39] || (_cache[39] = [
                    _createTextVNode("50 minutes")
                  ])),
                  _: 1
                }, 8, ["class"]),
                _createVNode(Button, {
                  type: "button",
                  onClick: _cache[9] || (_cache[9] = ($event: any) => {
              setTimeRanege(3300);
              reset();
            }),
                  class: _normalizeClass({ selected: _unref(selected) === 3300 })
                }, {
                  default: _withCtx(() => _cache[40] || (_cache[40] = [
                    _createTextVNode("55 minutes")
                  ])),
                  _: 1
                }, 8, ["class"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_unref(showTimeRanges) === true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(Button, {
                type: "button",
                onClick: _cache[10] || (_cache[10] = ($event: any) => {
            _isRef(showTimeRanges) //@ts-ignore
 ? showTimeRanges.value = false : showTimeRanges = false;
            saveTimeRange();
          })
              }, {
                default: _withCtx(() => _cache[41] || (_cache[41] = [
                  _createTextVNode("Save")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_unref(showTimeStints) === false && _unref(showTimeRanges) === false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("h2", null, "Timers: " + _toDisplayString(_unref(slowTime)) + "s slow | " + _toDisplayString(_unref(fastTime)) + "s fast", 1),
              _createVNode(Button, {
                type: "button",
                onClick: _cache[11] || (_cache[11] = ($event: any) => {
            _isRef(showTimeStints) //@ts-ignore
 ? showTimeStints.value = true : showTimeStints = true;
            _isRef(showTimeRanges) //@ts-ignore
 ? showTimeRanges.value = false : showTimeRanges = false;
          })
              }, {
                default: _withCtx(() => _cache[42] || (_cache[42] = [
                  _createTextVNode("Change Stints")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_unref(showTimeStints) === true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("h2", null, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[12] || (_cache[12] = ($event: any) => (_isRef(slowTimeRef) //@ts-ignore
 ? slowTimeRef.value -= 100 : slowTimeRef -= 100))
                  }, {
                    default: _withCtx(() => _cache[43] || (_cache[43] = [
                      _createTextVNode("-100")
                    ])),
                    _: 1
                  }),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[13] || (_cache[13] = ($event: any) => (_isRef(slowTimeRef) //@ts-ignore
 ? slowTimeRef.value -= 10 : slowTimeRef -= 10))
                  }, {
                    default: _withCtx(() => _cache[44] || (_cache[44] = [
                      _createTextVNode("-10")
                    ])),
                    _: 1
                  }),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[14] || (_cache[14] = ($event: any) => (_isRef(slowTimeRef) //@ts-ignore
 ? slowTimeRef.value-- : slowTimeRef--))
                  }, {
                    default: _withCtx(() => _cache[45] || (_cache[45] = [
                      _createTextVNode("-1")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("h1", null, _toDisplayString(_unref(slowTimeRef)), 1),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[15] || (_cache[15] = ($event: any) => (_isRef(slowTimeRef) //@ts-ignore
 ? slowTimeRef.value++ : slowTimeRef++))
                  }, {
                    default: _withCtx(() => _cache[46] || (_cache[46] = [
                      _createTextVNode("+1")
                    ])),
                    _: 1
                  }),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[16] || (_cache[16] = ($event: any) => (_isRef(slowTimeRef) //@ts-ignore
 ? slowTimeRef.value += 10 : slowTimeRef += 10))
                  }, {
                    default: _withCtx(() => _cache[47] || (_cache[47] = [
                      _createTextVNode("+10")
                    ])),
                    _: 1
                  }),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[17] || (_cache[17] = ($event: any) => (_isRef(slowTimeRef) //@ts-ignore
 ? slowTimeRef.value += 100 : slowTimeRef += 100))
                  }, {
                    default: _withCtx(() => _cache[48] || (_cache[48] = [
                      _createTextVNode("+100")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[18] || (_cache[18] = ($event: any) => (_isRef(fastTimeRef) //@ts-ignore
 ? fastTimeRef.value -= 100 : fastTimeRef -= 100))
                  }, {
                    default: _withCtx(() => _cache[49] || (_cache[49] = [
                      _createTextVNode("-100")
                    ])),
                    _: 1
                  }),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[19] || (_cache[19] = ($event: any) => (_isRef(fastTimeRef) //@ts-ignore
 ? fastTimeRef.value -= 10 : fastTimeRef -= 10))
                  }, {
                    default: _withCtx(() => _cache[50] || (_cache[50] = [
                      _createTextVNode("-10")
                    ])),
                    _: 1
                  }),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[20] || (_cache[20] = ($event: any) => (_isRef(fastTimeRef) //@ts-ignore
 ? fastTimeRef.value-- : fastTimeRef--))
                  }, {
                    default: _withCtx(() => _cache[51] || (_cache[51] = [
                      _createTextVNode("-1")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("h1", null, _toDisplayString(_unref(fastTimeRef)), 1),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[21] || (_cache[21] = ($event: any) => (_isRef(fastTimeRef) //@ts-ignore
 ? fastTimeRef.value++ : fastTimeRef++))
                  }, {
                    default: _withCtx(() => _cache[52] || (_cache[52] = [
                      _createTextVNode("+1")
                    ])),
                    _: 1
                  }),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[22] || (_cache[22] = ($event: any) => (_isRef(fastTimeRef) //@ts-ignore
 ? fastTimeRef.value += 10 : fastTimeRef += 10))
                  }, {
                    default: _withCtx(() => _cache[53] || (_cache[53] = [
                      _createTextVNode("+10")
                    ])),
                    _: 1
                  }),
                  _createVNode(Button, {
                    type: "button",
                    onClick: _cache[23] || (_cache[23] = ($event: any) => (_isRef(fastTimeRef) //@ts-ignore
 ? fastTimeRef.value += 100 : fastTimeRef += 100))
                  }, {
                    default: _withCtx(() => _cache[54] || (_cache[54] = [
                      _createTextVNode("+100")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _createVNode(Button, {
                type: "button",
                onClick: _cache[24] || (_cache[24] = ($event: any) => {
            saveTimeStints();
            _isRef(showTimeStints) //@ts-ignore
 ? showTimeStints.value = false : showTimeStints = false;
          })
              }, {
                default: _withCtx(() => _cache[55] || (_cache[55] = [
                  _createTextVNode("Save")
                ])),
                _: 1
              }),
              _createVNode(Button, {
                type: "button",
                onClick: _cache[25] || (_cache[25] = ($event: any) => (_isRef(showTimeStints) //@ts-ignore
 ? showTimeStints.value = false : showTimeStints = false))
              }, {
                default: _withCtx(() => _cache[56] || (_cache[56] = [
                  _createTextVNode("Cancel")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _cache[62] || (_cache[62] = _createElementVNode("div", null, " ", -1)),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(TimerBle, {
          seconds: _unref(timer).seconds,
          minutes: _unref(timer).minutes
        }, null, 8, ["seconds", "minutes"]),
        (_unref(showTimers) === true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("h1", null, _toDisplayString(_unref(percentDec)) + ",", 1),
              _createElementVNode("h2", null, _toDisplayString(_unref(percentFrac)) + " % left", 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(Button, {
          type: "button",
          onClick: _cache[26] || (_cache[26] = ($event: any) => {
          startTimers();
          _isRef(showTimers) //@ts-ignore
 ? showTimers.value = true : showTimers = true;
        })
        }, {
          default: _withCtx(() => _cache[57] || (_cache[57] = [
            _createTextVNode("Start")
          ])),
          _: 1
        }),
        _createVNode(Button, {
          type: "button",
          onClick: _cache[27] || (_cache[27] = ($event: any) => (pauseTimers()))
        }, {
          default: _withCtx(() => _cache[58] || (_cache[58] = [
            _createTextVNode("Pause")
          ])),
          _: 1
        }),
        _createVNode(Button, {
          type: "button",
          onClick: _cache[28] || (_cache[28] = ($event: any) => (resumeTimers()))
        }, {
          default: _withCtx(() => _cache[59] || (_cache[59] = [
            _createTextVNode("Resume")
          ])),
          _: 1
        }),
        _createVNode(Button, {
          type: "button",
          onClick: _cache[29] || (_cache[29] = ($event: any) => (reload()))
        }, {
          default: _withCtx(() => _cache[60] || (_cache[60] = [
            _createTextVNode(" Restart ")
          ])),
          _: 1
        }),
        _createVNode(Button, {
          type: "button",
          onClick: _cache[30] || (_cache[30] = ($event: any) => (reset()))
        }, {
          default: _withCtx(() => _cache[61] || (_cache[61] = [
            _createTextVNode(" Reset ")
          ])),
          _: 1
        })
      ])
    ]),
    (_unref(showTimers) === true)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([{ intervalFast: _unref(swap), intervalSlow: !_unref(swap) }, "full"])
        }, [
          _cache[65] || (_cache[65] = _createElementVNode("p", null, null, -1)),
          (_unref(swap) === true)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_13, [
                _cache[63] || (_cache[63] = _createTextVNode(" Fast ")),
                (_unref(fastTimes) > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_unref(fastTimes)) + " left", 1))
                  : _createCommentVNode("", true),
                (_unref(fastTimes) < 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_15, "last"))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_unref(swap) === false)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_16, [
                _cache[64] || (_cache[64] = _createTextVNode(" Slow ")),
                (_unref(slowTimes) > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_unref(slowTimes)) + " left", 1))
                  : _createCommentVNode("", true),
                (_unref(slowTimes) < 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_18, "last"))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("h1", _hoisted_19, [
            _createVNode(TimerBle, {
              seconds: _unref(timerSlow).seconds,
              minutes: _unref(timerSlow).minutes
            }, null, 8, ["seconds", "minutes"])
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}
}

})